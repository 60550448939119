/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactTablev8 from '../UI/Table/ReactTablev8';

function InvoicesTable({ dataTable, columnFilter }) {
  const navigate = useNavigate(); // navigate function
  const { customerId } = useParams(); // get customer ID, the component its renderenderd from customers sections will hide customers column
  const isRecurrent = dataTable.some((row) => row.recurrent);

  // Table scheme definition
  const tableSetup = [
    { header: 'Filter', accessorKey: 'filterChips', className: 'd-none' },
    {
      header: 'Expand',
      accessorKey: 'recurrent',
      isExpandable: true,
      type: 'expand',
      enableGlobalFilter: false,
      className: `${!isRecurrent && 'd-none'}`,
    },
    {
      header: 'Status',
      accessorKey: 'status',
      type: 'statusIndicator',
      sortingFn: 'sortByLabel',
    },
    {
      header: 'Invoice',
      accessorKey: 'invoiceNumber',
      className: 'numbers text-nowrap',
    },
    {
      header: 'Created',
      accessorKey: 'dateCreated',
      className: 'numbers',
      type: 'date',
      sortingFn: 'datetime',
      mobileLabel: true,
    },
    {
      header: 'Due date',
      accessorKey: 'dateDue',
      className: 'numbers',
      type: 'date',
      sortingFn: 'datetime',
      mobileLabel: true,
    },
    {
      header: 'Send',
      type: 'statusBadge',
      sortingFn: 'sortByLabel',
      accessorKey: 'send',
      mobileLabel: true,
    },
    {
      header: 'Amount',
      accessorKey: 'amount',
      type: 'money',
    },
    {
      header: 'Balance',
      accessorKey: 'balance',
      type: 'money',
      mobileLabel: true,
    },
  ];
  if (!customerId) {
    tableSetup.splice(6, 0, {
      header: 'Customer name',
      accessorKey: 'customerName',
      className: 'anchor',
      type: 'link',
      sortingFn: 'sortByLabel',
      mobileLabel: true,
    });
  }

  // Action on row Click
  const HandleRowClick = (rowId) => (rowId ? navigate(`${rowId}`) : '');

  // Setup initial (default) sort
  const initialInvoiceSort = React.useMemo(() => {
    return [
      {
        id: 'invoiceNumber',
        desc: true,
      },
    ];
  }, []);

  return (
    <ReactTablev8
      className="invoices-table"
      dataTable={dataTable}
      tableLayout={tableSetup}
      tableAction={HandleRowClick}
      columnfilter={columnFilter}
      initialSortBy={initialInvoiceSort}
      mobileClass="invoices_table"
    />
  );
}

export default InvoicesTable;
