/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
import propTypes from 'prop-types';
import ActionMenu from '../../UI/buttons/ActionMenu';
import DataCurrency from '../../UI/Table/td/DataCurrency';

function SelectedProductsItem({ index, item, onDelete, onModify }) {
  const {
    item_price_before_tax,
    category_name,
    description,
    product_name,
    price_before_tax,
    quantity,
  } = item;

  const handleDelete = () => onDelete(index);
  const handleModify = () => onModify(index);

  const itemData = [
    {
      header: 'index',
      content: index + 1,
    },
    {
      header: 'category',
      content: category_name,
    },
    {
      header: 'name',
      content: product_name,
    },
    {
      header: 'description',
      content: description,
      className: 'row-span-2',
      style: { whiteSpace: 'pre-wrap' },
    },
    {
      header: 'quantity',
      content: quantity,
    },
    {
      header: 'rate',
      content: <DataCurrency value={+price_before_tax} />,
    },
    {
      header: 'amount',
      content: <DataCurrency value={+item_price_before_tax} />,
    },
    {
      header: 'actions',
      content: (
        <ActionMenu handleDelete={handleDelete} handleEdit={handleModify} />
      ),
    },
  ];

  return (
    <>
      {itemData.map((data) => (
        <td
          key={data.header}
          className={data?.className || ''}
          style={data?.style || {}}
        >
          {data.content}
        </td>
      ))}
    </>
  );
}

SelectedProductsItem.propTypes = {
  item: propTypes.shape({
    item_price_before_tax: propTypes.oneOfType([
      propTypes.string,
      propTypes.number,
    ]).isRequired,
    product_name: propTypes.string.isRequired,
    description: propTypes.string.isRequired,
    price_before_tax: propTypes.oneOfType([propTypes.string, propTypes.number])
      .isRequired,
    quantity: propTypes.oneOfType([propTypes.string, propTypes.number])
      .isRequired,
    category_name: propTypes.string.isRequired,
  }).isRequired,
  index: propTypes.number.isRequired,
  onDelete: propTypes.func.isRequired,
  onModify: propTypes.func.isRequired,
};

export default SelectedProductsItem;
