import React from 'react';
import propTypes from 'prop-types';
import AddProductButton from './AddProductButton';
import TotalsTable from './TotalsTable';

function Footer({ setShow, items }) {
  const calculateTotalTax = (quantity, rate, tax) => {
    return quantity * rate * (tax / 100).toFixed(4);
  };

  const calculateTotal = (quantity, rate, tax) => {
    return quantity * rate + calculateTotalTax(quantity, rate, tax);
  };

  const totals = {
    tax: items.reduce(
      (acc, e) =>
        acc + calculateTotalTax(+e.quantity, +e.price_before_tax, +e.tax_rate),
      0
    ),
    total: items.reduce(
      (acc, e) =>
        acc + calculateTotal(+e.quantity, +e.price_before_tax, +e.tax_rate),
      0
    ),
  };

  return (
    <tfoot>
      <tr>
        <AddProductButton length={items.length} setShow={setShow} />
        {items.length !== 0 && <TotalsTable totals={totals} />}
      </tr>
    </tfoot>
  );
}

Footer.propTypes = {
  setShow: propTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  items: propTypes.array.isRequired,
};

export default Footer;
