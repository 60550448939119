import React from 'react';
import propTypes from 'prop-types';
import DataCurrency from '../../UI/Table/td/DataCurrency';

function TotalsTable({ totals: { tax, total } }) {
  return (
    <>
      <td className="pt-4" aria-label="label" />
      <td className="pt-4  pb-5">
        TAX TOTAL
        <br />
        TOTAL
      </td>
      <td className="pt-4 pb-5" aria-label="label">
        <DataCurrency value={tax} />
        <DataCurrency value={total} />
      </td>
    </>
  );
}

TotalsTable.propTypes = {
  totals: propTypes.shape({
    tax: propTypes.number.isRequired,
    total: propTypes.number.isRequired,
  }).isRequired,
};

export default TotalsTable;
