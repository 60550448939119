import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectWithDefault from './SelectWithDefault';
import getSelectData from './getSelectData';

function SelectCustomer({
  handleChange,
  isInvalid,
  name,
  setFieldValue,
  value,
  config = {
    address: 'billing_address',
    email: 'customer_email',
    name: null,
  },
}) {
  const options = getSelectData('/customers');

  useEffect(() => {
    if (!options) return;
    const selectedCustomer = options.filter(
      (customer) => `${customer.value}` === `${value}`
    )[0];
    // This AND allows for the clearing of the fields when we remove the customer
    // from the select
    if (!selectedCustomer && value !== '') return;
    console.log('updating fields');
    const selectedAddress = selectedCustomer?.address || '';
    const selectedEmail = selectedCustomer?.email || '';
    const selectedName = selectedCustomer?.name || '';
    if (config.address) setFieldValue(config.address, selectedAddress);
    if (config.email) setFieldValue(config.email, selectedEmail);
    if (config.name) setFieldValue(config.name, selectedName);
  }, [value, options]);

  return (
    <SelectWithDefault
      name={name}
      options={options}
      value={value}
      isInvalid={isInvalid}
      handleChange={(e) => handleChange(e)}
      setFieldValue={setFieldValue}
    />
  );
}

SelectCustomer.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isInvalid: PropTypes.bool,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  config: PropTypes.shape({
    address: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default SelectCustomer;
